import { template as template_6ead7e970a5c48d4859b0ae2fa194b9c } from "@ember/template-compiler";
const FKControlMenuContainer = template_6ead7e970a5c48d4859b0ae2fa194b9c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
