import { template as template_6bbfd4d4337d4b69b612ae5a3d24afc5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6bbfd4d4337d4b69b612ae5a3d24afc5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
