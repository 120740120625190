import { template as template_029f0f66df3c481399bdc813553529f4 } from "@ember/template-compiler";
const FKText = template_029f0f66df3c481399bdc813553529f4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
